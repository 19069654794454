















































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import b64toBlob from 'b64-to-blob'
import moment from 'moment'
import { appendSitePrefix } from '@/utils/routeUtils'
import { mask } from 'vue-the-mask'
import { vxm } from '@/store'

export type IncomeHeaders = {
  completeView?: any
  singleView?: any
}

export type SelectedSupplier = {
  _id?: number | null
  code?: string | null
}

@Component({
  directives: {
    mask,
  },
})
export default class MekonomenMecaPriceTool extends Vue {
  supplierProducts = []
  suppliers = []
  suppliersForSelectBox = []
  headers = []
  selectedSupplier: SelectedSupplier = {}
  incomeHeaders: IncomeHeaders = {}
  filterLoading = true
  exportToExcelLoading = false
  search = ''
  brands = []
  tyreTypes = []
  supplierPriceTypes = []
  dimensionMask = '###/##-##'
  filteringPanel = [0]
  filteringSupplierPriceType = null
  filteringPriceDifference = null
  filteringDimension = null
  filteringBrands = null
  filteringTyreTypes = null
  priceDifferenceSign = 'more'
  priceDifferenceIcon = 'chevron_right'

  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: -1,
    sortBy: [],
    totalItems: 0,
  }

  appendSitePrefix = appendSitePrefix

  get excelFileName() {
    const date = moment().format('YYYY-MM-DD')
    if (this.selectedSupplier._id === 0) {
      return 'meca-complete-price-tool-' + date + '.xlsx'
    } else {
      return 'meca-price-tool-' + this.selectedSupplier.code + '-' + date + '.xlsx'
    }
  }

  get requestData() {
    return {
      supplier_id: this.selectedSupplier._id,
      price_difference: this.filteringPriceDifference,
      dimension: this.filteringDimension,
      brands: this.filteringBrands,
      tyre_types: this.filteringTyreTypes,
      price_difference_sign: this.priceDifferenceSign,
      price_list_id: this.filteringSupplierPriceType,
    }
  }

  created() {
    this.$axios.get('/v3/mekonomen/meca_price_tool/get_initial_data').then((response) => {
      this.tyreTypes = response.data.data.tyreTypes
      this.brands = response.data.data.brands

      this.supplierPriceTypes = response.data.data.priceListCustomers
      const supplierPriceTypeCostPriceOption = {
        id: 0,
        customer: {
          name: this.$t('c:mekonomen-price-tool:Cost price (net)') as string,
        },
      }
      this.supplierPriceTypes.unshift(supplierPriceTypeCostPriceOption)
      this.filteringSupplierPriceType = supplierPriceTypeCostPriceOption.id

      this.incomeHeaders = response.data.data.headers
      this.headers = this.incomeHeaders.completeView

      this.suppliers = response.data.data.suppliers
      this.suppliersForSelectBox = this.suppliers.slice(0)
      const selectAllOption = { _id: 0, code: '0', name: this.$t('c:common:Select all') as string }
      this.suppliersForSelectBox.unshift(selectAllOption)
      this.selectedSupplier = selectAllOption

      this.getProducts()
    })
  }

  changePriceDifferenceSign() {
    if (this.priceDifferenceIcon === 'chevron_left') {
      this.priceDifferenceIcon = 'chevron_right'
      this.priceDifferenceSign = 'more'
    } else {
      this.priceDifferenceIcon = 'chevron_left'
      this.priceDifferenceSign = 'less'
    }
  }

  getProducts() {
    this.filterLoading = true

    if (this.selectedSupplier._id === 0) {
      this.headers = this.incomeHeaders.completeView
    } else {
      this.headers = this.incomeHeaders.singleView
    }

    this.$axios
      .post('/v3/mekonomen/meca_price_tool/get_supplier_products', this.requestData)
      .then((response) => {
        this.supplierProducts = response.data.data.products
      })
      .catch((err) => {
        this.supplierProducts = []
        vxm.alert.onAxiosError(err, 'Error getting results')
      })
      .finally(() => {
        this.filterLoading = false
      })
  }

  exportToExcel() {
    this.exportToExcelLoading = true

    this.$axios
      .post('/v3/mekonomen/meca_price_tool/get_supplier_products/export_to_excel', this.requestData)
      .then((response) => {
        const blob = b64toBlob(response.data.data.binary, 'application/vnd.ms-excel')
        if (!window.navigator.msSaveOrOpenBlob) {
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', this.excelFileName)
          document.body.appendChild(link)
          link.click()
        } else {
          // BLOB FOR EXPLORER 11
          const url = window.navigator.msSaveOrOpenBlob(blob, this.excelFileName)
        }
      })
      .finally(() => {
        this.exportToExcelLoading = false
      })
  }
}
